import { BASE_ROUTE, RouteParams } from "../constants";

export const PROJECT_ROUTES = {
  index: {
    path: `${BASE_ROUTE}/projects/${RouteParams.projectId}`,
    generateLink: (organisationId: number | string, projectId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}`
  },
  createView: {
    path: `${BASE_ROUTE}/projects/create`,
    generateLink: () => PROJECT_ROUTES.createView.path
  },
  programmes: {
    index: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/programmes/${RouteParams.programmeId}`,
      generateLink: (organisationId: number | string, projectId: number | string, programmeId: string | number) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/programmes/${programmeId}`
    },
    locations: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/programmes/${RouteParams.programmeId}/locations`,
      generateLink: (organisationId: number | string, projectId: number | string, programmeId: string | number) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/programmes/${programmeId}/locations`
    }
  },
  organisationVisualisations: {
    sunburst: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/organisations/visualisations/sunburst`,
      generateLink: (organisationId: number | string, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/organisations/visualisations/sunburst`
    },
    timeline: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/organisations/visualisations/timeline`,
      generateLink: (organisationId: number | string, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/organisations/visualisations/timeline`
    },
    capacity: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/organisations/visualisations/capacity`,
      generateLink: (organisationId: number | string, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/organisations/visualisations/capacity`
    },
    heatmap: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/organisations/visualisations/heatmap/${RouteParams.section}`,
      generateLink: (organisationId: number | string, projectId: number | string, section: string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/organisations/visualisations/heatmap/${section}`
    }
  },
  stakeholders: {
    listView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/stakeholders`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/stakeholders`
    },
    extendedView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/stakeholders/${RouteParams.stakeholderId}`,
      generateLink: (organisationId: string | number, projectId: number | string, stakeholderId: string | number) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/stakeholders/${stakeholderId}`
    },
    visualisations: {
      index: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/stakeholders/visualisations/`,
        generateLink: (organisationId: string | number, projectId: number | string, stakeholderId: string | number) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/stakeholders/visualisations/`
      },
      parameters: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/stakeholders/visualisations/${RouteParams.stakeholderType}/${RouteParams.profilingType}`,
        generateLink: (
          organisationId: string | number,
          projectId: number | string,
          stakeholderType: string | number,
          profilingType: string | number
        ) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/stakeholders/visualisations/${stakeholderType}/${profilingType}`
      }
    }
  },
  audiences: {
    listView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/audiences`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/audiences`
    },
    readinessTrackingView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/audiences/readiness-tracking`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/audiences/readiness-tracking`
    },
    readinessChartsView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/audiences/readiness-charts`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/audiences/readiness-charts`
    },
    visualisations: {
      index: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/audiences/visualisations/`,
        generateLink: (organisationId: string | number, projectId: number | string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/audiences/visualisations`
      },
      params: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/audiences/visualisations/${RouteParams.section}`,
        generateLink: (organisationId: string | number, projectId: number | string, section: string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/audiences/visualisations/${section}`
      }
    },
    extendedView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/audiences/${RouteParams.stakeholderId}`,
      generateLink: (organisationId: string | number, projectId: number | string, stakeholderId: string | number) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/audiences/${stakeholderId}`
    }
  },
  impactGroups: {
    listView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impact-groups`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/impact-groups`
    },
    visualisations: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impact-groups/visualisations/summary`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/impact-groups/visualisations/summary`
    },
    editView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impact-groups/${RouteParams.impactGroupId}/edit`,
      generateLink: (organisationId: string | number, projectId: number | string, impactGroupId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/impact-groups/${impactGroupId}/edit`
    },
    createView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impact-groups/create`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/impact-groups/create`
    },
    extendedView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impact-groups/${RouteParams.impactGroupId}`,
      generateLink: (organisationId: string | number, projectId: number | string, impactGroupId: string | number) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/impact-groups/${impactGroupId}`
    }
  },
  actions: {
    listView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/actions`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/actions`
    },
    extendedView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/actions/${RouteParams.actionId}`,
      generateLink: (organisationId: string | number, projectId: number | string, actionId: string | number) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/actions/${actionId}`
    },
    visualisations: {
      index: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/actions/visualisations`,
        generateLink: (organisationId: string | number, projectId: number | string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/actions/visualisations`
      },
      breakdown: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/actions/visualisations/breakdown`,
        generateLink: (organisationId: string | number, projectId: number | string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/actions/visualisations/breakdown`
      },
      parameters: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/actions/visualisations/${RouteParams.page}`,
        generateLink: (organisationId: string | number, projectId: number | string, page: string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/actions/visualisations/${page}`
      }
    }
  },
  impacts: {
    listView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impacts`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/impacts`
    },
    extendedView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impacts/${RouteParams.impactId}`,
      generateLink: (organisationId: string | number, projectId: number | string, impactId: string | number) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/impacts/${impactId}`
    },
    visualisations: {
      index: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impacts/visualisations`,
        generateLink: (organisationId: string | number, projectId: number | string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/impacts/visualisations`
      },
      parameters: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impacts/visualisations/${RouteParams.page}`,
        generateLink: (organisationId: string | number, projectId: number | string, page: string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/impacts/visualisations/${page}`
      },
      impactAssesment: {
        path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impacts/visualisations/impact-assessment`,
        generateLink: (organisationId: string | number, projectId: number | string) =>
          `${BASE_ROUTE.replace(
            RouteParams.organisationId,
            organisationId + ""
          )}/projects/${projectId}/impacts/visualisations/impact-assessment`
      }
    }
  },
  processes: {
    listView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/processes`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/processes`
    },
    reports: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/processes-reports`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/processes-reports`
    },
    processRoleMapping: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/processes/role-mapping`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/processes/role-mapping`
    }
  },
  training: {
    index: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/training`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/training`
    },
    trackerView: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/training/tracker`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/training/tracker`
    },
    extendedView: {
      // path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/impacts/${RouteParams.impactId}`,
      // generateLink: (organisationId: string | number, projectId: number | string, impactId: string | number) =>
      //   `${BASE_ROUTE.replace(
      //     RouteParams.organisationId,
      //     organisationId + ""
      //   )}/projects/${projectId}/impacts/${impactId}`
    }
  },
  settings: {
    index: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/settings/index`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/settings/index`
    },
    milestones: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/settings/milestones`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/settings/milestones`
    },
    benefits: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/settings/benefits`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/settings/benefits`
    },
    phases: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/settings/phases`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/projects/${projectId}/settings/phases`
    },
    permissions: {
      path: `${BASE_ROUTE}/projects/${RouteParams.projectId}/settings/permissions`,
      generateLink: (organisationId: string | number, projectId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/projects/${projectId}/settings/permissions`
    }
  }
};
