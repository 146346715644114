export enum CommonColDefFieldNamesEnum {
  Selected = "selected",
  RefNumber = "refNumber",
  Name = "name",
  Owner = "owner",
  Tags = "tags",
  StartDate = "startDate",
  EndDate = "endDate",
  ActualEndDate = "actualEndDate",
  ProgressStatus = "progressStatus",
  CreatedBy = "createdBy",
  CreatedAt = "createdAt",
  ModifiedBy = "modifiedBy",
  UpdatedAt = "updatedAt",
  ReviewedBy = "reviewedBy",
  ReviewedOn = "reviewedOn",
  NextReviewDue = "nextReviewDue",
  NoteCount = "noteCount",
  Description = "description",
  Impacts = "impacts",
  Actions = "actions",
  ProjectName = "projectName",
  ProgrammeName = "programmeName",
  Deadline = "deadline",
  Processes = "processes",
  UiActionCol = "uiActionCol"
}

export enum ProcessColDefFieldNamesEnum {
  UniqueIdentifier = "uniqueIdentifier",
  Level1 = "level1",
  Level2 = "level2",
  Level3 = "level3",
  Level4 = "level4",
  Level5 = "level5",
  ScopeItems = "scopeItems",
  IsInScope = "isInScope"
}

export enum ProjectProcessColDefFieldNamesEnum {
  ProcessApps = "processApps",
  ProcessRoles = "processRoles",
  ImpactGroups = "impactGroups",
  Impacts = "impacts",
  Actions = "actions",
  ProcessJobRoles = "processJobRoles",
  StakeholderOwners = "stakeholderOwners",
  Tags = "tags"
}

export enum ImpactColDefFieldNamesEnum {
  ImpactTypes = "impactTypes",
  TotalPeopleImpacted = "totalPeopleImpacted",
  ImpactLevel = "impactLevel",
  BusinessAreas = "businessAreas",
  Locations = "locations",
  ImpactGroups = "impactGroups",
  Actions = "actions",
  ProjectStakeholders = "projectStakeholders",
  Audiences = "audiences",
  Milestones = "milestones"
}

export enum ActionColDefFieldNamesEnum {
  RagStatus = "ragStatus",
  AssignedTo = "assignedToName",
  ActionStakeholders = "actionStakeholders",
  ActionAudiences = "actionAudiences",
  ProjectStakeholderOwners = "projectStakeholderOwners",
  ActionTypeName = "actionTypeName",
  Audiences = "audiences"
}

export enum StakeholderColDefFieldNamesEnum {
  Email = "email",
  Role = "role",
  BusinessArea = "businessArea",
  Influence = "influence",
  OwnerNames = "owners",
  IsKeyStakeholder = "isKeyStakeholder",
  Sentiment = "sentiment",
  Commitment = "commitment",
  Receptiveness = "receptiveness",
  OwnedActions = "ownedActions",
  Impact = "impact",
  Tags = "tags",
  CreatedBy = "createdBy",
  CreatedAt = "createdAt",
  ModifiedBy = "modifiedBy",
  UpdatedAt = "updatedAt",
  ReviewedBy = "reviewedBy",
  LastReviewedOn = "reviewedOn",
  NextReviewDue = "nextReviewDue",
  NoteCount = "notes",
  StakeholderId = "stakeholderId"
}

export enum AudienceColDefFieldNamesEnum {
  Impact = "impact",
  ImpactActions = "impactActions",
  IsKeyStakeholder = "isKeyStakeholder",
  Size = "size",
  Influence = "influence",
  Count = "count",
  AudienceCount = "audienceCount",
  Awareness = "awareness",
  Understanding = "understanding",
  Commitment = "commitment",
  Capability = "capability",
  Adoption = "adoption",
  UiActionCol = "uiActionCol"
}

export enum MilestoneColDefFieldNamesEnum {
  Deadline = "deadline",
  MilestoneTypes = "milestoneType"
}

export enum PhaseColDefFieldNamesEnum {
  Milestones = "milestones",
  Project = "project",
  Phases = "phases",
  AddPulse = "addPulse",
  ViewPulses = "viewPulses",
  Pulses = "pulses",
  TotalPulses = "totalPulses"
}
export enum PulseColDefFieldNamesEnum {
  Phase = "phase",
  Audiences = "audiences"
}

export enum AuccaColDefFieldNamesEnum {
  Awareness = "awareness",
  Understanding = "understanding",
  Commitment = "commitment",
  Capability = "capability",
  Adoption = "adoption"
}

export enum TrainingModuleColDefFieldNamesEnum {
  ModuleId = "moduleId",
  TrainingModuleStatus = "trainingModuleStatus",
  Duration = "duration"
}
